import { Box, Grid, TextField, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import user from '../../api/user';
import { changemainlang } from '../../common/Methodcommon';
import { StaticBaseURL } from '../../common/constants';
import BoxDirection from '../Common/BoxDirection';
import Compdirection from '../Common/CompDirection';
import ForgetPasswordModal from './ForgetPasswordModal';
import './Login.css';
import LoginLoader from './LoginLoader';
import { useNavigate } from 'react-router';
import UserContext from '../../context/UserContext';
import { Button } from '../../components';
import {
  loginValidation,
  otpValidation,
  validatePassword,
} from './validations';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import ChangePasswordModal from './ChangePasswordModal';
import MobileRedirect from '../../components/MobileRedirect/MobileRedirect';

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#7C0E6F',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C0E6F',
    color: '#7C0E6F',
  },
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
});

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [resetToken, setResetToken] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [userLang, setuserLang] = useState(
    localStorage.getItem('applang') ? localStorage.getItem('applang') : 'en',
  );

  const { handleLogin } = useContext(UserContext);
  const [showPasswordExipry, setShowPasswordExipry] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState('');
  const [successbarMsg, setSuccessbarMsg] = useState(null);

  const handleLoginBtn = (e) => {
    e.preventDefault();

    const formDate = {
      username,
      password,
    };

    loginValidation
      .validate(formDate, { abortEarly: false })
      .then(() => {
        const payload = {
          username,
          password,
        };

        setErrors({});
        setIsLoading(true);

        if (validatePassword(password)) {
          user
            .resetPasswordToken(payload)
            .then((res) => {
              setIsLoading(false);
              setShowPasswordExipry(false);
              setOpenChangePasswordModal(true);
              setResetToken(res?.data?.reset_password_token);
            })
            .catch((err) => {
              setSnackbarMsg(
                err?.message?.includes('422')
                  ? err?.response?.data?.data?.message
                  : 'Something went wrong!',
              );
              setIsLoading(false);
            });
        } else {
          user
            .login(payload)
            .then((res) => {
              if (res?.data?.password_expired !== undefined) {
                setShowPasswordExipry(true);
                setOpenChangePasswordModal(true);
                setResetToken(res?.data?.reset_password_token);
              } else if (res?.data?.otp !== undefined && res?.data?.otp) {
                setShowOtp(true);
                setSuccessbarMsg(t('login.OTP has been sent successfully'));
              } else {
                localStorage.setItem('__current_company_id', res?.company_id);

                handleLogin(true);

                navigate({
                  pathname: `/company/init`,
                  search: `?xCompanyId=${res?.company_id}&user_id=${res.user.id}`,
                });
              }

              setIsLoading(false);
            })
            .catch((err) => {
              setSnackbarMsg(
                err?.message?.includes('422')
                  ? err?.response?.data?.data?.message
                  : 'Something went wrong!',
              );

              setIsLoading(false);
            });
        }
      })
      .catch((err) => {
        const newErrors = {};

        err.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });

        setErrors(newErrors);
      });
  };

  const handleOtpBtn = async (e) => {
    e.preventDefault();
    const formDate = {
      username,
      password,
      otp,
    };

    try {
      await otpValidation.validate(formDate, { abortEarly: false });

      const payload = {
        username,
        password,
        otp,
      };

      setErrors({});
      setIsLoading(true);

      try {
        const res = await user.verifyOtp(payload);

        if (res?.data?.password_expired !== undefined) {
          setShowPasswordExipry(true);
          setOpenChangePasswordModal(true);
          setResetToken(res?.data?.reset_password_token);
        } else {
          localStorage.setItem('__current_company_id', res?.company_id);
          handleLogin(true);

          navigate({
            pathname: `/company/init`,
            search: `?xCompanyId=${res?.company_id}&user_id=${res.user.id}`,
          });
        }
      } catch (err) {
        setSnackbarMsg(
          err?.message?.includes('422')
            ? err?.response?.data?.data?.message
            : 'Something went wrong!',
        );
      } finally {
        setIsLoading(false);
      }
    } catch (err) {
      const newErrors = {};

      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });

      setErrors(newErrors);
    }
  };

  function changeLang() {
    const newlang = userLang === 'en' ? 'ar' : 'en';
    localStorage.setItem('applang', newlang);
    setlangdata(newlang);
  }
  function setlangdata(newlang) {
    if (newlang === userLang) return;
    setuserLang(newlang);
    i18n.changeLanguage(newlang);
    changemainlang(newlang, true);
  }

  useEffect(() => {
    if (localStorage.getItem('applang')) {
      const newlang = localStorage.getItem('applang');
      setlangdata(newlang);
    } else {
      const newlang = i18n.language;
      localStorage.setItem('applang', newlang);
      setlangdata(newlang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${StaticBaseURL}login_bg.png)`,
      }}
      className="w-full h-screen flex overflow-y-hidden overflow-x-hidden flex-row items-center bg-cover"
    >
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500,
          },
        }}
      />
      <Grid
        className="login-img-box"
        item
        xs={12}
        sx={{
          paddingLeft: '8.313em',
          position: 'relative',
        }}
      >
        <img src={`${StaticBaseURL}login_logo.png`} alt="" />
      </Grid>
      <BoxDirection>
        <div className="bg-white rounded-[20px] w-[417px] h-[500px]">
          {showOtp ? (
            <>
              <Grid container>
                <Grid item xs={12} className="welcome-back">
                  {t('login.OTP')}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div className="uppercase text-[#757575] mt-4">
                    {t('login.Kindly enter the OTP to Login')}
                  </div>
                </Grid>
                <form onSubmit={handleOtpBtn} className="mt-5 w-full p-5">
                  <Compdirection>
                    <StyledTextField
                      placeholder={t('login.Enter OTP')}
                      label={t('login.Enter OTP')}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      type="number"
                      fullWidth
                      error={Boolean(errors.otp)}
                      helperText={errors.otp || ' '}
                    />
                  </Compdirection>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      className="primary-btn w-full !p-5"
                      type="submit"
                      onClick={handleOtpBtn}
                      disabledClassName="primary-btn justify-between hover:!bg-primary hover:!text-white w-full cursor-not-allowed"
                      disabled={isLoading}
                    >
                      <span className="w-10"></span>
                      <span>{t('login.Login')}</span>

                      <span className="w-10 !cursor-not-allowed">
                        {isLoading && <LoginLoader width={70} height={50} />}
                      </span>
                    </Button>
                  </Grid>
                </form>
                <Grid item xs={12}>
                  <Box
                    className="login-change-lang mt-5"
                    style={{
                      textAlign: 'center',
                      paddingRight: '0.5em',
                      paddingBottom: '0.5em',
                    }}
                  >
                    <div
                      className="cursor-pointer font-bold text-primary font-[]"
                      onClick={() => changeLang()}
                    >
                      {userLang === 'ar'
                        ? 'Change to English'
                        : 'تغيير إلى اللغة العربية'}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container>
                <Grid item xs={12} className="welcome-back">
                  {t('login.Welcome Back!')}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div className="uppercase text-[#757575] mt-4">
                    {t('login.Please log in to your account')}
                  </div>
                </Grid>
                <form
                  onSubmit={(e) => {
                    handleLoginBtn(e);
                  }}
                  className="mt-5 w-full p-5"
                >
                  <Compdirection>
                    <StyledTextField
                      placeholder={t('login.Username')}
                      label={t('login.Username')}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="string"
                      fullWidth
                      error={Boolean(errors.username)}
                      helperText={errors.username || ' '}
                    />
                  </Compdirection>

                  <Compdirection>
                    <div className="mt-4">
                      <StyledTextField
                        placeholder={t('login.Password')}
                        label={t('login.Password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        error={Boolean(errors.password)}
                        helperText={errors.password || ' '}
                        InputProps={{
                          endAdornment: (
                            <i
                              className={`las ${showPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-2xl`}
                              onClick={() => setShowPassword(!showPassword)}
                              style={{ cursor: 'pointer' }}
                            ></i>
                          ),
                        }}
                      />
                    </div>
                  </Compdirection>
                  <Box
                    className="login-forgot-pass"
                    style={{
                      textAlign: 'right',
                      paddingRight: '1.5em',
                      paddingBottom: '0.5em',
                    }}
                  >
                    <Typography
                      style={{
                        color: '#9E9E9E',
                        cursor: 'pointer',
                      }}
                      onClick={() => setOpenModal(true)}
                    >
                      {t('login.Forgot Password?')}
                    </Typography>
                  </Box>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      className="primary-btn w-full !p-5"
                      type="submit"
                      disabledClassName="primary-btn justify-between hover:!bg-primary hover:!text-white w-full cursor-not-allowed"
                      disabled={isLoading}
                    >
                      <span className="w-10"></span>
                      <span>{t('login.Login')}</span>

                      <span className="w-10 !cursor-not-allowed">
                        {isLoading && <LoginLoader width={70} height={50} />}
                      </span>
                    </Button>
                  </Grid>
                </form>
                <Grid item xs={12}>
                  <Box
                    className="login-change-lang mt-5"
                    style={{
                      textAlign: 'center',
                      paddingRight: '0.5em',
                      paddingBottom: '0.5em',
                    }}
                  >
                    <div
                      className="cursor-pointer font-bold text-primary font-[]"
                      onClick={() => changeLang()}
                    >
                      {userLang === 'ar'
                        ? 'Change to English'
                        : 'تغيير إلى اللغة العربية'}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      </BoxDirection>

      {openModal && (
        <ForgetPasswordModal
          closeModal={() => setOpenModal(false)}
          isOpen={openModal}
        />
      )}

      {openChangePasswordModal && (
        <ChangePasswordModal
          closeModal={() => setOpenChangePasswordModal(false)}
          isOpen={openChangePasswordModal}
          username={username}
          resetToken={resetToken}
          showPasswordExipry={showPasswordExipry}
        />
      )}

      <Snackbar
        open={snackbarMsg ? true : false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={4000}
        onClose={() => setSnackbarMsg(null)}
      >
        <SnackbarContent
          message={snackbarMsg}
          style={{
            background: '#ff5a5a',
          }}
        />
      </Snackbar>

      <Snackbar
        open={successbarMsg ? true : false}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={4000}
        onClose={() => setSuccessbarMsg(null)}
      >
        <SnackbarContent
          message={successbarMsg}
          style={{
            background: '#21D8A5',
          }}
        />
      </Snackbar>
      <MobileRedirect />
    </div>
  );
};
export default Login;
